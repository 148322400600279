import React from 'react'
import itinProcessImage from '../images/itin/process.svg'
import { NavLink } from 'react-router-dom'
function ItinInfo() {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className="  w-full bg-bluegray round px-6 sm:px-16 py-12 sm:py-16 flex flex-col justify-center gap-24 ">
        <div className="flex flex-row justify-between ">
          <div className=" flex flex-col justify-center gap-8 w-full lg:w-1/2  sm:pr-8">
            <h2 className="font-sans font-bold text-[#ffffff] text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl ">
              ITIN Başvuru Süreci ve Belgeler
            </h2>
            <p className="flex flex-col gap-1 font-sans font-light text-[#ffffff] text-sm lg:text-sm xl:text-base 2xl:text-xl text-start list-decimal">
              <li>
                Başvuru posta yoluyla yapılabileceği gibi, belirlenmiş bir IRS
                Vergi Mükellefi Yardım Merkezi (TAC) aracılığıyla şahsen de
                yapılabilir. Bir ITIN’e başvurmak için başvuru sahibi şunları
                yapmalıdır: Federal gelir vergisi beyannamesi ile birlikte bir
                W-7 Formu, IRS Bireysel Vergi Mükellefi Kimlik Numarası
                Başvurusu doldurmalı Başvuru sahipleri, doğrudan başvurabilir
                veya bir ücret karşılığında başvuru sürecinde yardımcı
                olabilecek bir tasdik edici kabul acentesine başvuruda
                bulunabilir. ITIN’ler, belgeleme ve imza gereklilikleri
                karşılanırsa ve geçerli bir vergi beyannamesi üzerinde talep
                edilirse, eş veya bakmakla yükümlü olunan kişiler için de
                verilebilir.
              </li>
              <br />
              <li>
                <b className=" font-bold">
                  ITIN almak için genellikle şu belgelere ihtiyacınız olacak:{' '}
                </b>{' '}
                <br />
                -Eksiksiz bir IRS Formu W-7 <br /> -ABD federal gelir vergisi{' '}
                <br />
                beyannamesi -Pasaport
              </li>
              <br />
              <li>
                <b className=" font-bold">ITIN Nedir ve Ne İşe Yarar?</b> <br />
                ITIN, sosyal güvenlik numarası alamayan nitelikli kişilere
                verilen bir numaradır. ITIN, vergi formlarında kullanılır.
                Amerika’da ikamet eden işverenler, vergi beyanlarını sosyal
                güvenlik numaraları ile yapar. Amerika’da ikamet etmeyen yabancı
                vatandaşların sosyal güvenlik numaraları olmadığından dolayı
                beyan işlemlerini yapabilmeleri için kullanılan vergi numarası
                ITIN olup işlemler bu numara ile gerçekleştirilir.
              </li>
              <br />
            </p>
            <div className="flex flex-row w-full justify-center lg:justify-start">
              <button className=" bg-orange round  hover:bg-red w-52 h-14  xl:w-56 xl:h-12 2xl:w-80 2xl:h-16 justify-center ">
                <NavLink
                  to="/iletisim
                  "
                  className="text-[#ffffff] text-lg xl:text-xl 2xl:text-2xl"
                >
                  Bizimle İletişime Geçin!
                </NavLink>
              </button>
            </div>
          </div>

          <div className="  w-2/5 hidden lg:block">
            <img
              src={itinProcessImage}
              alt="resale-process illustration"
              className="w-full self-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItinInfo
