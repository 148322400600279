import React, { useState } from 'react'
import PriceCardForm from './PriceCardForm'
import { NavLink } from 'react-router-dom'
import thick from '../../../images/logo&icon/thick.svg'
function PriceCard({ priceList }) {
  const [formPrice, setFormPrice] = useState(priceList.price)

  return (
    <div className="bg-[#ffffff] shadow-xl round-md w-64 md:w-56 lg:w-80 xl:w-96 min-[1700px]:w-[345px] h-auto flex flex-row justify-center p-8">
      <div className="grid justify-items-center ">
        <h2 className=" font-sans font-medium underline underline-offset-4 decoration-from-font text-2xl  lg:text-3xl xl:text-4xl pb-2 lg:pb-4 xl:pb-8">
          {priceList.title}
        </h2>
        <div className="flex flex-row xl:pb-2">
          <h2 className="font-sans font-bold text-lglg:text-xl xl:text-3xl">
            $
          </h2>
          <h2 className="font-sans font-bold text-2xl lg:text-4xl xl:text-5xl 2xl:text-4xl">
            {formPrice}
          </h2>
        </div>

        <div
          className={`pb-2 grid justify-center w-full  ${
            !priceList.button && 'invisible'
          } `}
        >
          <PriceCardForm
            setFormPrice={setFormPrice}
            formOption={priceList.formOption}
          />
        </div>

        <ul className=" pb-2 md:pb-2 lg:pb-4 xl:pb-8 ">
          {priceList.features.map((item) => (
            <div className="flex flex-row p-1">
              <img src={thick} className=" w-8 lg:w-10 pr-4" alt="thick" />
              <li className=" text-xs md:text-md lg:text-lg xl:text-xl">
                {item}
              </li>
            </div>
          ))}
        </ul>

        <div className="">
          <NavLink
            to={priceList.link}
            className="text-[#ffffff] text-sm md:text-md lg:text-lg xl:text-xl"
          >
            <button className=" bg-orange round  hover:bg-red w-40 h-10  md:w-44 md:h-10  lg:w-52 lg:h-14  xl:w-64 xl:h-16 2xl:w-54 2xl:h-14 justify-center">
              Daha Fazla Bilgi İçin!
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default PriceCard
