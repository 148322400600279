import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { motion } from 'framer-motion'

function ProcessCard({ processImage, processList }) {
  const idKeypair = []
  processList.map((item) => {
    idKeypair.push({ isOpen: false })
  })
  const [textKey, setTextKey] = useState(idKeypair)

  const assignKey = (id) => {
    if (textKey[id].isOpen) {
      const updatedTextKey = [...idKeypair]
      setTextKey(updatedTextKey)
    } else {
      const updatedTextKey = [...idKeypair]
      updatedTextKey[id].isOpen = !updatedTextKey[id].isOpen
      setTextKey(updatedTextKey)
    }
  }

  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className=" flex flex-row justify-between p-10 sm:p-24 shadow-xl round w-full ">
        <div className="flex flex-row justify-start hidden min-[1174px]:block 2xl:w-[660px] ">
          <img
            src={processImage}
            alt="process-illustration"
            className="w-full"
          />
        </div>

        <div className="grid grid-cols-1 content-start justify-items-center min-[1174px]:pl-4  w-[660px] ">
          <h2 className="font-sans font-semibold text-3xl min-[1174px]:text-4xl xl:text-4xl 2xl:text-5xl sm:px-8 pb-8 xl:pb-12 2xl:pb-16 pt-2">
            Süreç Nasıl İşliyor?
          </h2>

          <ul className="w-full flex flex-col gap-4 lg:gap-6 xl:gap-8 sm:pl-8">
            {processList.map((item) => (
              <li key={item.id} className=" flex flex-col w-full gap-4 ">
                <button
                  className="flex flex-row justify-between text-base lg:text-xl xl:text-2xl 2xl:text-3xl border-y py-3 border-[#D4D4D4] border-solid w-full "
                  onClick={() => {
                    assignKey(item.id)
                  }}
                >
                  <h2 className=" font-sans font-medium text-base min-[1174px]:text-lg xl:text-xl 2xl:text-2xl text-start ">
                    {item.header}
                  </h2>

                  {textKey[item.id].isOpen ? (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="  pt-[4px] rotate-90 duration-150"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className=" text-md  pt-[4px] "
                    />
                  )}
                </button>

                {textKey[item.id].isOpen && (
                  <motion.p
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="font-sans font-light text-sm min-[1174px]:text-base min-[1174px]:pt-2 w-full  "
                  >
                    {item.text}
                  </motion.p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProcessCard
