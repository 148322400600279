import React from 'react'
import star from '../../../images/logo&icon/star.svg'
import commas from '../../../images/logo&icon/commas.svg'
function CommentCard({ commentList }) {
  return (
    <div className="bg-[#ffffff] grid grid-cols-1 justify-items-center rounded-3xl px-6 py-4 w-72 md:w-40 min-[936px]:w-48 lg:w-60 xl:w-72 2xl:w-96 min-[1766px]:w-[460px]">
      <div className="flex flex-row pb-4">
        <img src={star} alt="star" className=" w-8 md:w-6 xl:w-12" />
        <img src={star} alt="star" className=" w-8 md:w-6 xl:w-12" />
        <img src={star} alt="star" className=" w-8 md:w-6 xl:w-12" />
        <img src={star} alt="star" className=" w-8 md:w-6 xl:w-12" />
        <img src={star} alt="star" className=" w-8 md:w-6 xl:w-12" />
      </div>

      <p className="font-sans font-light text-md md:text-xs min-[936px]:text-sm lg:text-md xl:text-lg 2xl:text-xl text-center pb-2">
        {commentList.comment}
      </p>
      <h2 className="font-sans font-medium text-xl  md:text-sm text-center min-[936px]:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
        {commentList.person}
      </h2>
      <p className="font-sans font-light text-md md:text-xs lg:text-sm xl:text-md 2xl:text-lg">
        {commentList.title}
      </p>
      <div className="flex flex-row justify-start w-full">
        <img src={commas} alt="commas" className="  w-14 md:w-10 lg:w-18" />
      </div>
    </div>
  )
}

export default CommentCard
