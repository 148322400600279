import React from 'react'
import { useState } from 'react'
import PriceCardForm from '../components/homepage/pricesection/PriceCardForm'
import { NavLink } from 'react-router-dom'
import thick from '../images/logo&icon/thick.svg'
function PriceCard2({ priceList }) {
  const [formPrice, setFormPrice] = useState(priceList.price)

  return (
    <div className="bg-[#1A222D] shadow-xl round-md w-[350px] sm:w-72 md:w-72 lg:w-80 xl:w-96 2xl:w-[510px] min-[1700px]:w-[550px] h-auto flex flex-row justify-center p-8 ">
      <div className="grid justify-items-center ">
        <h2 className=" font-sans text-[#ffffff] font-medium underline underline-offset-4 decoration-from-font text-3xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl pb-4 md:pb-2 lg:pb-4 xl:pb-6">
          {priceList.title}
        </h2>
        <div className="flex flex-row pb-4 md:pb-0 xl:pb-2">
          <h2 className="font-sans text-[#ffffff] font-bold text-2xl md:text-2xl lg:text-2xl xl:text-4xl">
            $
          </h2>
          <h2 className="font-sans text-[#ffffff] font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl">
            {formPrice}
          </h2>
        </div>

        <div
          className={`pb-2 grid justify-center w-full  ${
            !priceList.button && 'invisible'
          } `}
        >
          <PriceCardForm
            setFormPrice={setFormPrice}
            formOption={priceList.formOption}
          />
        </div>

        <ul className=" pb-4 md:pb-2 lg:pb-4 xl:pb-8">
          {priceList.features2.map((item) => (
            <div className="flex flex-row p-1">
              <img src={thick} className=" w-8 lg:w-10 pr-4" alt="thick" />
              <li className=" text-md md:text-xs md:text-lg lg:text-lg xl:text-xl 2xl:text-3xl text-[#ffffff]">
                {item}
              </li>
            </div>
          ))}
        </ul>

        <div className="">
          <button className=" bg-orange round  hover:bg-red w-40 h-10  md:w-44 md:h-12  lg:w-52 lg:h-14  xl:w-64 xl:h-16 2xl:w-64 2xl:h-16 justify-center">
            <NavLink
              to="/iletisim"
              className="text-[#ffffff] text-sm md:text-md lg:text-lg xl:text-xl"
            >
              Bizimle İletişime Geçin!
            </NavLink>
          </button>
        </div>
      </div>
    </div>
  )
}

export default PriceCard2
