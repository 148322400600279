import React from 'react'
import { NavLink } from 'react-router-dom'

function HeroSection({ data }) {
  return (
    <div className=" bg-bluegray w-auto h-auto pb-12 round-b ">
      <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-between pt-24 px-28">
        <div className=" md:w-3/5 lg:w-1/2  flex flex-col mt-8 justify-center ">
          <h1 className="font-sans font-semibold text-[#ffffff] text-4xl md:text-4xl lg:text-5xl xl:text-6xl text-center mb-8 ">
            {data.title}
          </h1>
          <p className="font-sant font-thin text-lg md:text-sm lg:text-md xl:text-lg text-[#ffffff] text-center pb-8">
            {data.text}
          </p>
          <div className="w-full flex flex-row justify-center">
            <button className=" bg-orange round p-2 px-4 hover:bg-red w-72 h-16 justify-center">
              <NavLink to={data.link} className="text-[#ffffff] text-xl">
                Hemen İletişime Geç!
              </NavLink>
            </button>
          </div>
        </div>
        <div className=" md:w-3/5 lg:w-fit ">
          <img
            src={data.image}
            className=" w-full"
            alt="round-newyork-illustration"
          />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
