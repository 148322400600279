import React from 'react'
import { NavLink } from 'react-router-dom'
import phoneDesign from '../images/logo&icon/phone-design-new.svg'
import phoneDesign2 from '../images/logo&icon/phone-design-2-new.svg'

function Cta() {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28   ">
      <div className=" grid grid-cols-1 justify-items-start w-full bg-bluegray round">
        <div className="flex flex-row justify-between overflow-hidden pb-10">
          <div className="grid grid-cols-1 justify-items-start px-16  ">
            <h2 className="font-sans font-medium text-[#ffffff] text-3xl lg:text-4xl xl:text-5xl 2xl:text-7xl pb-4 py-10">
              Hemen şimdi bize ulaşın ve işinizi kolaylaştıralım!
            </h2>
            <p className="font-sans font-light text-[#ffffff] text-sm lg:text-lg xl:text-xl  2xl:text-2xl">
              Profesyonel danışmanlarımız, Amazon hesabı açma, banka hesabı
              oluşturma, şirket kurulumu ve daha fazlası konularında size
              yardımcı olmaya hazır.
            </p>
          </div>
          <img
            src={phoneDesign}
            className=" w-32 lg:w-36 xl:w-42 2xl:w-58 hidden md:block pt-1 lg:pt-0 "
            alt="phone-design"
          />
        </div>
        <div className=" flex flex-row justify-between w-4/5 px-16 pb-8 md:pb-0">
          <NavLink
            to="/iletisim"
            className="text-[#ffffff] text-sm md:text-md lg:text-lg xl:text-2xl"
          >
            <button className=" bg-orange round  hover:bg-red w-40 h-10  md:w-44 md:h-10  lg:w-52 lg:h-14  xl:w-64 xl:h-16 2xl:w-80 2xl:h-16 justify-center ">
              Bizimle İletişime Geçin!
            </button>
          </NavLink>
          <img
            src={phoneDesign2}
            alt="phone-design-2"
            className=" w-32 lg:w-36 xl:w-42 2xl:w-58  hidden md:block pb-2 md:pb-0 "
          />
        </div>
      </div>
    </div>
  )
}

export default Cta
