import uspic from '../images/home/us.png'
import itinpic from '../images/home/itin.png'
import tradepic from '../images/home/trademark.png'
import resalepic from '../images/home/resale.png'

import usheropic from '../images/us/newyork.svg'
import itinheropic from '../images/itin/liberty-statue.svg'
import trademarkheropic from '../images/trademark/trademark.svg'
import resaleheropic from '../images/resale/resale.svg'

import amazonpic from '../images/partners/amazon-logo.svg'
import mercurypic from '../images/partners/mercury-logo.svg'
import northwestpic from '../images/partners/northwest-logo.svg'
import sellerfulfilmentpic from '../images/partners/seller-fullfilment-logo.svg'
import wisepic from '../images/partners/wise-logo.svg'
import payoneerpic from '../images/partners/payoneer-logo.svg'

export const navBarList = [
  {
    _id: 1001,
    title: 'Anasayfa',
    link: '/',
  },
  {
    _id: 1002,
    title: 'Hizmetlerimiz',
    link: '',
  },
  {
    _id: 1003,
    title: 'Ücretler',
    link: '/#price',
  },
  {
    _id: 1004,
    title: 'Hakkımızda',
    link: '/about',
  },
  {
    _id: 1005,
    title: 'İletişim',
    link: '/iletisim',
  },
  {
    _id: 1005,
    title: 'İletişim',
    link: '/iletisim',
  },
]

export const hizmetList = [
  {
    _id: 1001,
    title: 'Amerika Paketi',
    link: '/amerika-hizmeti',
  },
  {
    _id: 1002,
    title: 'Marka Tescili',
    link: '/marka-tescili',
  },
  {
    _id: 1003,
    title: 'ITIN Hizmeti',
    link: '/itin-hizmeti',
  },
  {
    _id: 1004,
    title: 'Resale Sertifika',
    link: '/resale-sertifika',
  },
]

export const hizmetsliderlist = [
  {
    _id: 1001,
    title: 'Amerika Paketi',
    text: 'Hızlı ve Güvenilir Şirket Kurulumu ile şirketinizi hızla kurun, Ücretsiz EIN Numarası ile vergi kimlik numaranızı ücretsiz alın ve Uzman Danışmanlık ile işletme süreçlerinizi en etkili şekilde yönetin.',
    image: uspic,
    link: '/amerika-hizmeti',
  },
  {
    _id: 1002,
    title: 'ITIN Hizmeti',
    text: 'Hızlı ve Güvenilir Şirket Kurulumu ile şirketinizi hızla kurun, Ücretsiz EIN Numarası ile vergi kimlik numaranızı ücretsiz alın ve Uzman Danışmanlık ile işletme süreçlerinizi en etkili şekilde yönetin.',
    image: itinpic,
    link: '/itin-hizmeti',
  },
  {
    _id: 1003,
    title: 'Marka Tescili',
    text: 'Hızlı ve Güvenilir Şirket Kurulumu ile şirketinizi hızla kurun, Ücretsiz EIN Numarası ile vergi kimlik numaranızı ücretsiz alın ve Uzman Danışmanlık ile işletme süreçlerinizi en etkili şekilde yönetin.',
    image: tradepic,
    link: '/marka-tescili',
  },
  {
    _id: 1004,
    title: 'Resale Sertifika',
    text: 'Hızlı ve Güvenilir Şirket Kurulumu ile şirketinizi hızla kurun, Ücretsiz EIN Numarası ile vergi kimlik numaranızı ücretsiz alın ve Uzman Danışmanlık ile işletme süreçlerinizi en etkili şekilde yönetin.',
    image: resalepic,
    link: '/resale-sertifika',
  },
]

export const partnerList = [
  {
    _id: 1001,
    alt: 'amazon',
    image: amazonpic,
  },
  {
    _id: 1002,
    alt: 'mercury',
    image: mercurypic,
  },
  {
    _id: 1003,
    alt: 'northwest',
    image: northwestpic,
  },
  {
    _id: 1004,
    alt: 'seller-fullfilment',
    image: sellerfulfilmentpic,
  },
  {
    _id: 1005,
    alt: 'wise',
    image: wisepic,
  },
  {
    _id: 1006,
    alt: 'payooner',
    image: payoneerpic,
  },
]

export const priceList = [
  {
    id: 1000,
    title: 'Amerika Paketi',
    price: 999,
    button: true,
    formOption: [
      {
        id: 1,
        name: 'Illionis',
        price: 999,
      },
      {
        id: 2,
        name: 'Florida',
        price: 949,
      },
      {
        id: 3,
        name: 'Wyoming',
        price: 899,
      },
    ],
    features: [
      'Şirket Kurulumu',
      'EIN',
      'Banka Hesabı Açılışı',
      'Amazon Hesabı Açılışı',
      'Reseller Sertifika Alımı',
    ],
    features2: [
      'Aynı Gün Başvuru',
      'Şirket Kurulum Masrafları',
      'Şirket Adı Uygunluk Tespiti',
      'Şirket Resmi Adresi Tahsisi',
      'Yasal Şirket Evrakları',
      'Registered Agent Hizmeti ',
      'Şirket Yönetim Paneli',
      'Dijital Posta Yönlendirme',
      '1 yıllık domain ve hosting hizmeti',
      ' Şirketiniz için Website kurulumu',
      'Şirketiniz için Özel Mail Adresleri',
      'Amerika alan kodlu Telefon Numarası Tahsisi',
      'EIN Başvurusu',
      'FINCEN BOI Report',
      ' İstediğiniz Eyalette Resale Certificate',
      'Şirket Banka Hesabı',
      'Amazon Hesap açılışı',
    ],

    link: '/amerika-hizmeti',
  },
  {
    id: 1001,
    title: 'ITIN Paketi',
    price: 499,
    button: false,
    formOption: [
      {
        id: 1,
        name: 'Illionis',
        price: 1000,
      },
      {
        id: 2,
        name: 'Florida',
        price: 900,
      },
      {
        id: 3,
        name: 'Wyoming',
        price: 800,
      },
    ],

    features: [
      'Başvuru Ücretleri',
      'Gerekli Belgeler & Formlar',
      'Profesyonel Danışmanlık',
      'Onaylı Başvuru Merkezi',
      'İnceleme ve Onay Süreci',
    ],
    features2: [
      'Başvuru Ücretleri',
      'Gerekli Belgeler & Formlar',
      'Profesyonel Danışmanlık',
      'Onaylı Başvuru Merkezi',
      'İnceleme ve Onay Süreci',
    ],

    link: '/itin-hizmeti',
  },
  {
    id: 1002,
    title: 'Marka Tescili',
    price: 999,
    button: true,
    formOption: [
      {
        id: 1,
        name: 'USA',
        price: 999,
      },
      {
        id: 2,
        name: 'CANADA',
        price: 599,
      },
      {
        id: 3,
        name: 'UK',
        price: 599,
      },
      {
        id: 3,
        name: 'EU',
        price: 1499,
      },
      {
        id: 3,
        name: 'GERMANY',
        price: 699,
      },
      {
        id: 3,
        name: 'AU',
        price: 699,
      },
    ],
    features: [
      'Tescil Başvuru Masrafları',
      'Marka ve Logo Uygunluğu',
      'Marka Tescili ve Evrakları',
      'Avukatlık Hizmetleri',
      'Amazon Marka Kaydı',
    ],
    features2: [
      'Aynı Gün Başvuru',
      'Tescil Başvuru Masrafları',
      ' Tescil Adı Uygunluk Tespiti',
      ' Tescil Logo Uygunluk Tespiti',
      ' Tescil Logo Oluşturma',
      ' Yasal Marka Tescil Evrakları',
      ' Avukatlık Hizmeti ',
      'Amazon Paneli Uygunluk tespiti',
      ' Brand Registry kaydının yapılması',
    ],
    link: '/marka-tescili',
  },
  {
    id: 1003,
    title: 'Resale Sertifika',
    price: 129,
    button: false,
    formOption: [
      {
        id: 1,
        name: 'Illionis',
        price: 1000,
      },
      {
        id: 2,
        name: 'Florida',
        price: 900,
      },
      {
        id: 3,
        name: 'Wyoming',
        price: 800,
      },
    ],
    features: [
      'Tek Fiyat (NJ-IL-FL)',
      'Satış Vergisi Muafiyeti',
      'Düşük Maliyet',
      'Daha İyi Satış Olanağı',
      'Daha İyi Mali Planlama',
    ],
    features2: [
      'Tek Fiyat (NJ-IL-FL)',
      'Satış Vergisi Muafiyeti',
      'Düşük Maliyet',
      'Daha İyi Satış Olanağı',
      'Daha İyi Mali Planlama',
    ],

    link: '/resale-sertifika',
  },
]
export const commentList = [
  {
    id: 1000,
    person: 'Yusuf S.',
    comment:
      'Amazon hesap açılış hizmeti aldım ve çok memnun kaldım! Profesyonel ekipleri sayesinde işlemim hızlı ve sorunsuz bir şekilde tamamlandı. Teşekkür ederim!',
    title: 'Founder/S**** LLC',
  },
  {
    id: 1001,
    person: 'Ali Sencer Y. ',
    comment:
      'Firmanın Amazon hesap açılış hizmetinden yararlandım ve son derece etkilendim. Hızlı yanıtları, samimi yaklaşımları ve uzmanlıkları sayesinde güvenilir bir deneyim yaşadım. Kesinlikle tavsiye ederim!',
    title: 'Founder/G**** LLC',
  },
  {
    id: 1002,
    person: 'Sude Nur A.',
    comment:
      'Amazon hesap açılış hizmeti alırken bu firmanın hizmetini tercih ettim ve kararımın doğru olduğunu gördüm. Profesyonel yaklaşımları ve detaylı rehberlikleriyle işimi kolaylaştırdılar. Teşekkürler!',
    title: 'Founder/A**** LLC',
  },
]

export const heroHizmetList = [
  {
    id: 50,
    title: "Amerika'da İş Kurmanın Kolay Yolu",
    text: "AmazonPeak Danışmanlık ile Amerika'da iş kurmak artık çok daha kolay! Ücretsiz EIN numarası alma, Mercury veya Wise banka hesabı açma, Florida, Wyoming veya Illinois'te şirket kurulumu gibi hizmetlerimizle hayalinizdeki işi kurmak için adım atın!",
    image: usheropic,
    link: '/amerika-hizmeti',
  },
  {
    id: 51,
    title: "Amerika'da Vergi Kolaylığı: ITIN",
    text: "Peak Amazon Danışmanlık olarak, Amerika'da iş yapacak müşterilerimize ITIN numarası alma hizmeti sunuyoruz. ITIN, ABD'de vergi beyanı ve iadesi için gereklidir. Uzman ekibimiz, en kısa sürede ITIN almanızı sağlayarak ABD'deki iş sürecinizi kolaylaştırır.",
    image: itinheropic,
    link: '/itin-hizmeti',
  },
  {
    id: 52,
    title: 'Markanızı Güvence Altına Alın!',
    text: "Markanız, emek ve yatırımlarınızın somut bir temsilidir ve onu korumak, yasal haklarınızı ve ticari başarınızı güvence altına almak için kritik önem taşır. Peak Amazon Danışmanlık olarak, Amerika'da marka tescili sürecinin her aşamasında size profesyonel rehberlik ve destek sunuyoruz.",
    image: trademarkheropic,
    link: '/marka-tescili',
  },
  {
    id: 53,
    title: 'Resale Sertifikası Alarak Vergi Yükünüzü Hafifletin!',
    text: "Peak Amazon Danışmanlık olarak, Amerika'da satış yapacak işletmelerin vergi muafiyeti için resale sertifikası alma sürecini kolaylaştırıyoruz. Uzman ekibimiz, tüm işlemleri sizin adınıza yürütür ve en iyi sonucu elde etmenizi sağlar.",
    image: resaleheropic,
    link: '/resale-sertifika',
  },
]

export const usProcessList = [
  {
    id: 0,
    header: '1. Danışmanlık ve İhtiyaç Belirleme',
    text: 'İlk adımda, Florida, Wyoming veya Illinois gibi iş kurmak istediğiniz eyaleti seçersiniz ve şirketinizin türünü (LLC, Corporation vb.) belirlersiniz. Ayrıca, şirketinizin ihtiyaçları ve hedefleri üzerine bir danışmanlık süreci başlatılır.',
  },
  {
    id: 1,
    header: '2. Eyalet Seçimi ve Şirket Türü Belirleme',
    text: 'Eyalet seçiminizi ve şirket türünüzü belirledikten sonra, iş kurma sürecinizin temel adımlarından birini tamamlamış olursunuz. Bu adımda, eyaletinizin iş yasalarına ve şirketinizin yapısına uygun bir karar vermeniz önemlidir.',
  },
  {
    id: 2,
    header: '3. Şirket İsmi, Adresi ve Belge Hazırlığı',
    text: 'Şirketinizin ismini belirledikten sonra, resmi olarak kullanabileceğiniz bir adres seçmelisiniz. Ayrıca, iş kurma süreciniz için gerekli olan belgeleri hazırlamalısınız. Bu belgeler genellikle şirketin kuruluş belgesi, şirket sözleşmesi ve diğer resmi belgeleri içerir.',
  },
  {
    id: 3,
    header: '4. Başvuru Süreci ve EIN Numarası Alma',
    text: "Şirketinizin resmi başvurusunu yaparken, aynı zamanda IRS'ten (Internal Revenue Service) EIN (Employer Identification Number) numarası almanız gerekmektedir. Bu numara, şirketinizin federal vergi kimliğini belirler ve vergi beyannameleri gibi işlemler için gereklidir.",
  },
  {
    id: 4,
    header: '5. Vergi Kaydı ve Banka Hesabı Açma',
    text: 'Şirketinizin eyalet ve federal vergi yükümlülüklerini yerine getirmek için vergi kaydını tamamlamanız gerekmektedir. Ayrıca, işletme hesabınızı açmalısınız. Banka hesabı, işletmenizin finansal işlemlerini yönetmek için gerekli olan bir araçtır.',
  },
  {
    id: 5,
    header: '6. Amazon Hesabı Açma ve Resale Certificate Alma',
    text: 'Eğer işiniz e-ticaret ise, Amazon gibi platformlarda satış yapabilmek için bir hesap oluşturmalısınız. Ayrıca, ürünlerinizi satışa sunabilmek için eyaletinizden resale sertifikası almanız gerekmektedir. Bu sertifika, vergi muafiyetini belgeleyen bir belgedir.',
  },
  {
    id: 6,
    header: '7. İletişim ve Danışmanlık',
    text: "Tüm bu süreçlerde, sizinle sürekli iletişim halinde olacak ve ihtiyaçlarınıza uygun çözümler sunacak bir danışmanlık hizmetinden yararlanabilirsiniz. Danışmanlarınız, işletmenizin Amerika'da başarılı olması için size rehberlik edecektir.",
  },
]

export const resaleProcessList = [
  {
    id: 0,
    header: '1. Eyalet Vergi Dairesine Başvuru',
    text: 'İşletmeniz eyalette vergi mükellefi olmalıdır. Vergi mükellefiyeti için eyalet vergi dairesine başvurmanız gerekmektedir. Başvuru, vergi beyannameleri düzenleme ve vergi ödemelerini gerçekleştirme gibi işlemleri içerir. Başvuru genellikle online veya şahsen yapılabilir.',
  },
  {
    id: 1,
    header: '2. Belgenin Kullanım Amacı',
    text: 'Başvuruda belgenin kullanım amacını açıklamak önemlidir. Belgenin hangi işlemlerde kullanılacağını belirtmek gereklidir. Doğru ve açık bir şekilde belirtilen kullanım amacı, başvurunun hızla işlenmesini sağlar.',
  },
  {
    id: 2,
    header: '3. Başvuru Evraklarının Gönderimi',
    text: 'Başvuru evraklarının eksiksiz ve doğru bir şekilde doldurulması ve teslim edilmesi gereklidir. İşletme kimlik bilgileri, vergi numarası, gelir bilgileri gibi bilgiler içerir. Ek belgelerin de tam olarak sunulması gerekebilir.',
  },
  {
    id: 3,
    header: '4. Başvurunuzun Onay Süreci',
    text: 'Başvurunuzun onay süreci eyaletin belirlediği prosedürlere göre işler. İncelenme, doğrulama ve gerektiğinde ek bilgi talebi gibi adımları içerir. Onaylandığında, genellikle "resale certificate" veya satış vergisi muafiyet belgesi verilir. Bu belge, işletmenizin mal alımlarında vergi ödememesini sağlar.',
  },
]
export const brandRegistaryProcessList = [
  {
    id: 0,
    header: '1. Marka Tescil İşlemleri',
    text: 'Tescil yapılacak ülkedeki mevzuat gereği belirli dönemlerde kullanım ispatı talep edildiğinden, sadece kullanılan ürün ve hizmetler için başvurulması gerekir.',
  },
  {
    id: 1,
    header: '2. Marka Tescil Uygunluk Sorgulama',
    text: 'Tescil yapılacak ülkedeki marka başvurusu öncesinde marka tescil uygunluk kontrolü ve araştırması yapılarak tescil başvurusu için hazırlık yapılır.',
  },
  {
    id: 2,
    header: '3. Marka Tescil Başvurusu',
    text: 'Alanlarında uzman olan avukatlarımız tarafından tescil yapılacak olan ülkenin marka tescil ofisine gerekli evrakların gönderilir ve başvuru yapılır.',
  },
  {
    id: 3,
    header: '4. Amazon Brand Registry Kaydı ',
    text: 'Alanlarında Tescil başvurusu yapılan markanızın Amazon panelinde bulunan Brand registry sekmesine gerekli evrakların yüklenmesi ve markanız amazon tarafından korumaya alınır.',
  },
]
