import React from 'react'
import { NavLink } from 'react-router-dom'
import newyork from '../../images/logo&icon/newyork-svg.svg'

function HomePageHero() {
  return (
    <div className="bg-bluegray w-auto h-auto pb-12 round-b shadow-2xl">
      <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-center pt-24 px-28">
        <div className=" md:w-3/5 lg:w-3/5 lg:gap-12 flex flex-col mt-8 justify-center e">
          <h1 className="font-sans font-semibold text-[#ffffff] text-3xl md:text-5xl lg:text-6xl text-center  mb-8">
            E-ticarette Başarınızı Garanti Altına Alın
          </h1>
          <p className="font-sant font-thin text-sm md:text-sm lg:text-lg text-[#ffffff] text-center pb-8">
            Amazon'da satış yapmak artık çok daha kolay! Mercury Bank ile
            finansal işlemlerinizde güvenilir bir partner bulun. Amerika'da
            şirket kurulumu ve vergi avantajları için doğru adrestesiniz.
          </p>
          <div className="w-full flex flex-row justify-center">
            <NavLink to="/iletisim" className="text-[#ffffff] text-xl">
              <button className=" bg-orange round p-2 px-4 hover:bg-red w-72 h-16 justify-center">
                Hemen İletişime Geç!
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageHero
