import React from 'react'
import PriceCard2 from './PriceCard2'
function MonoPrice({ priceList, uspriceimage }) {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className="flex flex-row justify-center md:justify-between w-9/10">
        <div className=" w-1/2 hidden md:block  ">
          <img src={uspriceimage} alt="us-price-image" />
        </div>
        <div className=" md:w-1/2  flex justify-end ">
          <PriceCard2 priceList={priceList} />
        </div>
      </div>
    </div>
  )
}

export default MonoPrice
