import React from 'react'
import HomePageHero from '../components/homepage/HomePageHero'
import HomeHizmetSlider from '../components/homepage/HomeHizmetSlider'
import Partner from '../components/homepage/Partner'
import PriceSection from '../components/homepage/pricesection/PriceSection'
import Comment from '../components/homepage/commentcard/Comment'
import Cta from '../components/Cta'
import { motion } from 'framer-motion'
import Whatsapp from '../components/Whatsapp'
function Homepage() {
  return (
    <motion.div
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <HomePageHero />
      <h2 className="font-sans font-medium text-4xl md:text-5xl lg:text-6xl flex justify-center py-8">
        Hizmetlerimiz
      </h2>
      <HomeHizmetSlider />
      <h2 className="font-sans font-medium text-4xl md:text-5xl lg:text-6xl flex justify-center py-8">
        Partnerlerimiz
      </h2>
      <Partner />
      <h2
        className="font-sans font-medium text-4xl md:text-5xl lg:text-6xl flex justify-center py-8"
        id="price"
      >
        Ücretler
      </h2>
      <PriceSection />
      <h2 className="font-sans font-medium text-4xl md:text-5xl lg:text-6xl flex justify-center py-8">
        Danışan Yorumları
      </h2>
      <Comment />
      <div className="py-16">
        <Cta />
      </div>
      <Whatsapp />
    </motion.div>
  )
}

export default Homepage
