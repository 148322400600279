import React from 'react'
import PriceCard from './PriceCard'
import { priceList } from '../../../constants'

function PriceSection() {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28">
      <div className=" grid grid-cols-1 md:grid md:grid-cols-2 min-[1700px]:flex min-[1700px]:flex-row min-[1700px]:justify-between min-[1700px]:px-16 gap-y-16 justify-items-center w-full bg-bluegray py-16 round">
        {priceList.map((item) => (
          <PriceCard priceList={item} />
        ))}
      </div>
    </div>
  )
}

export default PriceSection
