import './App.css'
import Homepage from './pages/Homepage'
import UsPage from './pages/UsPage'
import ItinPage from './pages/ItinPage'
import MarkaPage from './pages/MarkaPage'
import ResalePage from './pages/ResalePage'
import Contact from './pages/Contact'

import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import About from './pages/About'

const Layout = () => {
  return (
    <div>
      <Header />
      <ScrollRestoration />
      <Outlet />

      <Footer />
    </div>
  )
}

function App() {
  const router = createHashRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />}></Route>

          <Route path="/about" element={<About />}></Route>

          <Route path="/amerika-hizmeti" element={<UsPage />}></Route>
          <Route path="/marka-tescili" element={<MarkaPage />}></Route>
          <Route path="/itin-hizmeti" element={<ItinPage />}></Route>
          <Route path="/resale-sertifika" element={<ResalePage />}></Route>
          <Route path="/iletisim" element={<Contact />}></Route>
        </Route>
      </Route>
    )
  )
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}

export default App
