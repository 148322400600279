import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { hizmetsliderlist } from '../../constants/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import { AnimatePresence, motion } from 'framer-motion'

function HomeHizmetSlider() {
  const [sliderPointer, setSliderPointer] = useState(0)
  const [direction, setDirection] = useState()

  const controlSlider = (direction) => {
    if (direction === 'right') {
      setDirection(1)
      if (hizmetsliderlist.length - 1 - sliderPointer === 0) setSliderPointer(0)
      else setSliderPointer(sliderPointer + 1)
    } else if (direction === 'left') {
      setDirection(-1)
      if (
        hizmetsliderlist.length - 1 - sliderPointer ===
        hizmetsliderlist.length - 1
      )
        setSliderPointer(hizmetsliderlist.length - 1)
      else setSliderPointer(sliderPointer - 1)
    }
  }

  const variants = {
    initial: (direction) => {
      return {
        x: direction > 0 ? 200 : -200,
        opacity: 0,
      }
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        x: direction > 0 ? -200 : 200,
        opacity: 0,
      }
    },
  }

  const variants2 = {
    initial: (direction) => {
      return {
        x: direction > 0 ? 100 : -100,
        opacity: 0,
      }
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        x: direction > 0 ? -100 : 100,
        opacity: 0,
      }
    },
  }

  return (
    <div className=" mx-8 md:mx-16 lg:mx-28">
      <div className="w-full relative flex flex-col justify-center mix-blend-overlay    ">
        <AnimatePresence mode="wait" initial={false} custom={direction}>
          <motion.img
            src={hizmetsliderlist[sliderPointer].image}
            className=""
            alt=""
            variants={variants}
            animate="animate"
            initial="initial"
            exit="exit"
            key={hizmetsliderlist[sliderPointer]._id}
            custom={direction}
            transition={{ duration: 0.4 }}
          />
        </AnimatePresence>
        <div className="z-50 absolute w-full h-auto flex flex-row justify-between ">
          <button
            className="  flex flex-row items-center"
            onClick={() => controlSlider('left')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-[#ffffff] text-2xl hover:text-[#ffffff6d] md:text-6xl pl-2"
            />
          </button>
          <AnimatePresence mode="wait" initial={false} custom={direction}>
            <motion.div
              className="flex flex-col-reverse items-center lg:flex-row lg:justify-center duration-500 "
              variants={variants2}
              animate="animate"
              initial="initial"
              exit="exit"
              key={hizmetsliderlist[sliderPointer]._id}
              custom={direction}
              transition={{ duration: 0.2 }}
            >
              <div className=" md:w-3/5 lg:w-3/5 lg:gap-12 flex flex-col mt-8 justify-center ">
                <h1 className="font-sans font-semibold  text-[#ffffff] text-2xl md:text-5xl lg:text-6xl text-center mb-4 lg:mb-4">
                  {hizmetsliderlist[sliderPointer].title}
                </h1>
                <div className="flex flex-row justify-between w-full">
                  <p className="font-sant font-light text-xs md:text-md lg:text-xl text-[#ffffff] text-center pb-4 lg:pb-4">
                    {hizmetsliderlist[0].text}
                  </p>
                </div>

                <div className="w-full flex flex-row justify-center">
                  <NavLink
                    to={hizmetsliderlist[sliderPointer].link}
                    className="text-[#ffffff] text-sm lg:text-xl"
                  >
                    <button className=" bg-orange round  hover:bg-red w-36 h-8 shadow-xl sm:shadow-none md:w-40 md:h-10  lg:w-72 lg:h-16 justify-center">
                      Daha Fazla Bilgi
                    </button>
                  </NavLink>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <button
            className=" flex flex-row items-center"
            onClick={() => controlSlider('right')}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-[#ffffff] hover:text-[#ffffff6d]  text-2xl   md:text-6xl pr-2"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default HomeHizmetSlider
