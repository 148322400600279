import React from 'react'
import HeroSection from '../components/HeroSection'
import { heroHizmetList } from '../constants'
import ProcessCard from '../components/ProcessCard'
import processImage from '../images/trademark/process.svg'
import { brandRegistaryProcessList } from '../constants'
import { priceList } from '../constants'
import markapriceimage from '../images/trademark/payment.svg'
import MonoPrice from '../components/MonoPrice'
import Cta from '../components/Cta'
import { useState, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { motion } from 'framer-motion'
import Whatsapp from '../components/Whatsapp'
function MarkaPage() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 600)
  }, [])

  return (
    <div>
      {loading ? (
        <div className="absolute w-full h-full flex flex-row justify-center bg-bluegray pt-96">
          <PulseLoader color="#F96E49" size={30} margin={20} />
        </div>
      ) : (
        <motion.div
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <HeroSection data={heroHizmetList[2]} />
          <div className="py-16">
            <ProcessCard
              processImage={processImage}
              processList={brandRegistaryProcessList}
            />
          </div>
          <h2 className="font-sans font-medium text-4xl md:text-5xl lg:text-6xl flex justify-center py-8">
            Fiyatlar
          </h2>
          <MonoPrice priceList={priceList[2]} uspriceimage={markapriceimage} />
          <div className="py-16">
            <Cta />
          </div>
        </motion.div>
      )}
      <Whatsapp />
    </div>
  )
}

export default MarkaPage
