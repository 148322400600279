import React from 'react'
import CommentCard from './CommentCard'
import { commentList } from '../../../constants'
function Comment() {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className=" grid grid-cols-1 md:flex md:flex-row md:justify-between md:px-16 gap-y-16 justify-items-center w-full bg-bluegray py-16 round">
        {commentList.map((item) => (
          <CommentCard commentList={item} />
        ))}
      </div>
    </div>
  )
}

export default Comment
