import React from 'react'
import AboutHero from '../components/aboutpage/AboutHero'
import AboutInfo from '../components/aboutpage/AboutInfo'
import Cta from '../components/Cta'
import { useState, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { motion } from 'framer-motion'
import Whatsapp from '../components/Whatsapp'
function About() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 600)
  }, [])

  return (
    <div className="">
      {loading ? (
        <div className="absolute w-full h-full flex flex-row justify-center bg-bluegray pt-96 z-50">
          <PulseLoader color="#F96E49" size={30} margin={20} />
        </div>
      ) : (
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <AboutHero />
          <div className="py-16">
            <AboutInfo />
          </div>
          <Cta />
        </motion.div>
      )}
      <Whatsapp />
    </div>
  )
}

export default About
