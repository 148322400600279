import React from 'react'
import { NavLink } from 'react-router-dom'
function ResaleInfo({ processImage }) {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className="  w-full bg-bluegray  round px-6 sm:px-16 py-12 sm:py-16 flex flex-col justify-center gap-24 ">
        <div className="flex flex-row justify-between ">
          <div className=" flex flex-col justify-center gap-8 w-full lg:w-1/2  sm:pr-8">
            <h2 className="font-sans font-bold text-[#ffffff] text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl ">
              Satışlarda Vergi Avantajı: Resale Certificate!
            </h2>
            <p className="flex flex-col gap-1 font-sans font-light text-[#ffffff] text-sm lg:text-sm xl:text-base 2xl:text-xl text-start list-decimal">
              <li>
                <b className=" font-bold">Satış Vergisi Muafiyeti: </b>
                Resale Certificate, satın alınan malların satış vergisini ödeme
                zorunluluğunu kaldırır. Bu, işletmelerin satın alma
                maliyetlerini düşürmeye yardımcı olur.
              </li>
              <br />
              <li>
                <b className=" font-bold">Maliyetlerinizi Düşürür: </b>
                Satış vergisi muafiyeti işletmelerin genel maliyetlerini
                düşürür. Bu, işletmenin rekabet gücünü artırır ve kar marjını
                iyileştirir.
              </li>
              <br />
              <li>
                <b className=" font-bold">
                  Firmalar Arası İlişkileri Kolaylaştırır:{' '}
                </b>
                Resale Certificate, işletmeler arası alışverişleri
                kolaylaştırır. Belge, işletmelerin malları satın alırken satış
                vergisi ödememelerine olanak sağlar, bu da işletmeler arası
                işlemleri daha hızlı ve daha kolay hale getirir.
              </li>
              <br />
              <li>
                <b className=" font-bold">Daha Fazla Satış Olanağı: </b>
                Resale Certificate, işletmelerin daha geniş bir müşteri
                kitlesine ulaşmasını sağlar. İşletmeler, belge sayesinde diğer
                işletmelere vergisiz mal satma yeteneğine sahip olurlar, bu da
                satış olanaklarını genişletir.
              </li>
              <br />
              <li>
                <b className=" font-bold">Daha İyi Mali Planlama: </b>
                Satış vergisi ödeme yükümlülüğünün kaldırılması, işletmelerin
                mali planlamalarını daha etkili bir şekilde yapmalarını sağlar.
                Bu, işletmenin nakit akışını iyileştirir ve finansal istikrarı
                artırır.
              </li>
            </p>
            <div className="flex flex-row w-full justify-center lg:justify-start">
              <button className=" bg-orange round  hover:bg-red w-52 h-14  xl:w-56 xl:h-12 2xl:w-80 2xl:h-16 justify-center ">
                <NavLink
                  to="/iletisim"
                  className="text-[#ffffff] text-lg xl:text-xl 2xl:text-2xl"
                >
                  Bizimle İletişime Geçin!
                </NavLink>
              </button>
            </div>
          </div>

          <div className="  w-1/2 hidden lg:block">
            <img
              src={processImage}
              alt="resale-process illustration"
              className="w-full self-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResaleInfo
