import React from 'react'
import { NavLink } from 'react-router-dom'
import itinProcessImage from '../images/itin/process2.jpg'
function WhyItin() {
  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className="  w-full bg-bluegray  round px-6 sm:px-16 py-12 sm:py-16 flex flex-col justify-center gap-24 ">
        <div className="flex flex-row justify-between ">
          <div className=" flex flex-col justify-center gap-8 w-full lg:w-1/2  sm:pr-8">
            <h2 className="font-sans font-bold text-[#ffffff] text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl ">
              ITIN Numarası Neden Önemlidir?
            </h2>
            <p className="flex flex-col gap-1 font-sans font-light text-[#ffffff] text-sm lg:text-sm xl:text-base 2xl:text-xl text-start list-decimal">
              <br />
              ITIN numarasının önemi, Amerika’da sosyal güvenlik numarası
              olmayan kişilerin de Amerikan vergi kanunları kapsamına girmesini
              mümkün kılmasıdır. ITIN numarası ile yapılacaklar sınırlı olup bu
              numara sadece federal vergi beyanı amacıyla kullanılır ve sosyal
              güvenlik kapsamında olmadığı hâlde vergi yükümlülüğü bulunan
              bireylerin vergi beyanında bulunmalarını sağlar. <br /> ITIN
              numarası ile Amerika’da e-ticaret yapan yabancıların PayPal hesabı
              açmaları kolaydır. Farklı bankalarda şirket hesabı açmak, kredi
              başvurusu yapmak isteyenler için de bu numara önemlidir. Tüm
              bunların yanında ABD’de belirli bir süre ikamet eden ve vergi
              verme yükümlülüğü bulunan yabancılar ITIN numarası almak
              durumundadır. Aynısı ABD’de ikamet etmediği hâlde vergi
              beyannamesi vermesi gereken yabancılar, yabancı öğrenciler,
              akademisyenler ve araştırmacılar için de geçerlidir.
            </p>
            <div className="flex flex-row w-full justify-center lg:justify-start">
              <button className=" bg-orange round  hover:bg-red w-52 h-14  xl:w-56 xl:h-12 2xl:w-80 2xl:h-16 justify-center ">
                <NavLink
                  to="/iletisim"
                  className="text-[#ffffff] text-lg xl:text-xl 2xl:text-2xl"
                >
                  Bizimle İletişime Geçin!
                </NavLink>
              </button>
            </div>
          </div>

          <div className="  w-2/5 hidden lg:block">
            <img
              src={itinProcessImage}
              alt="resale-process illustration"
              className="w-full self-center round"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyItin
