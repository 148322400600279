import React from 'react'
import { partnerList } from '../../constants'
function Partner() {
  return (
    <div className=" mx-8 md:mx-16 lg:mx-28 bg-[#F9FBFF] w-auto h-auto round shadow-xl flex flex-row justify-center">
      <div className="w-full relative grid grid-cols-2 gap-8 justify-items-center lg:flex lg:flex-row lg:justify-between list-none p-16">
        {partnerList.map((item) => (
          <li id={item._id}>
            <img src={item.image} alt={item.alt} />
          </li>
        ))}
      </div>
    </div>
  )
}

export default Partner
