import React from 'react'
import { navBarList } from '../constants/index'
import { hizmetList } from '../constants/index'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import logo from '../images/logo&icon/Logo.svg'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

function Header() {
  const [showMenu, setShowMenu] = useState(true)
  const [sideNav, setSideNav] = useState(false)
  const [sideMenu, setSideMenu] = useState(false)
  const [sideHizmetMenu, setSideHizmetMenu] = useState(false)
  let timeout
  let scroll = 0

  /* hambuger menu for mobile */
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 1300) {
        setShowMenu(false)
        setSideNav(true)
      } else {
        setShowMenu(true)
        setSideNav(false)
      }
    }
    ResponsiveMenu()
    window.addEventListener('resize', ResponsiveMenu)
  }, [])

  /* Sticky header */
  useEffect(() => {
    window.onscroll = () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        if (scroll >= window.scrollY && window.scrollY > 10) {
          document.getElementById('header').classList.add('sticky')
        } else {
          document.getElementById('header').classList.remove('sticky')
        }

        scroll = window.scrollY
      }, 10)
    }
  }, [])

  const showSideMenu = () => {
    setSideMenu(true)
    setSideNav(false)
  }
  const closeSideMenu = () => {
    setSideMenu(false)
    setSideNav(true)
  }
  const closeSideHizmetMenu = () => {
    closeSideMenu()
    setSideHizmetMenu(!sideHizmetMenu)
  }

  return (
    <div
      className="  bg-bluegray flex items-center justify-between  z-50 "
      id="header"
    >
      <div className=" w-54 h-16 sm:ml-12 flex-none mt-4 mr-6">
        <Link to="/">
          <img
            className=" w-64 pt-2 sm:pt-0 sm:w-[350px]"
            src={logo}
            alt="logo"
          />
        </Link>
      </div>
      {showMenu && (
        <motion.ul
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className=" flex justify-end gap-24 mr-32 mt-4 w-auto "
        >
          <NavLink
            key={navBarList[0]._id}
            className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
            to={navBarList[0].link}
          >
            <li>{navBarList[0].title}</li>
          </NavLink>
          {/* Dropdown Menu */}
          <div>
            <li
              key={navBarList[1]._id}
              className=" flex font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0 relative cursor-pointer group"
            >
              <a className="mr-2" href="#">
                {navBarList[1].title}
              </a>
              <FontAwesomeIcon
                icon={faCaretDown}
                className="mt-1 group-hover:rotate-180 duration-300"
              />

              <ul className=" flex flex-col absolute z-[9999] rounded-md hidden group-hover:block bg-bluegray shadow-md  mt-6  ">
                {hizmetList.map((item) => (
                  <NavLink
                    className="text-[#ffffff]  duration-200"
                    key={item._id}
                    to={item.link}
                  >
                    <li className=" w-48 h-18 pt-2 pb-2 pl-2 rounded-md hover:bg-[#F96E49] duration-200 hover:text-[#c6c6c6] ">
                      {item.title}
                    </li>
                  </NavLink>
                ))}
              </ul>
            </li>
          </div>

          {/* Dropdown Links */}

          <NavLink
            key={navBarList[2]._id}
            className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
            to={navBarList[2].link}
          >
            <li>{navBarList[2].title}</li>
          </NavLink>
          <NavLink
            key={navBarList[3]._id}
            className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
            to={navBarList[3].link}
          >
            <li>{navBarList[3].title}</li>
          </NavLink>
          <NavLink
            key={navBarList[4]._id}
            className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
            to={navBarList[4].link}
          >
            <li>{navBarList[4].title}</li>
          </NavLink>
          {/* Dropdown Finish */}
        </motion.ul>
      )}
      {sideNav && (
        <button
          className="p-2 flex flex-row justify-end"
          onClick={() => showSideMenu()}
        >
          <FontAwesomeIcon
            icon={faBars}
            className=" text-[#ffffff] text-3xl pt-1  transition ease-in-out delay-150 bg-blue-500 hover:-translate-x-0 hover:scale-110 hover:text-[#c6c6c6] duration-50 w-10 h-10"
          />
        </button>
      )}
      {sideMenu && (
        <div className="fixed top-0 left-0 w-full h-screen bg-bluegray text-gray-200 bg-opacity-80 z-50">
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="w-[80%] h-full relative"
          >
            <div className=" flex flex-row bg-bluegray w-80  h-full z-50">
              <div className="mr-24 mt-8 ml-4  list-none ">
                <NavLink
                  key={navBarList[0]._id}
                  className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
                  to={navBarList[0].link}
                  onClick={closeSideMenu}
                >
                  <li className=" mb-4 text-2xl ">{navBarList[0].title}</li>
                </NavLink>
                <button
                  key={navBarList[1]._id}
                  className=" flex flex-row pr-2 font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
                  onClick={() => {
                    setSideHizmetMenu(!sideHizmetMenu)
                  }}
                >
                  <li className=" mb-4 text-2xl ">{navBarList[1].title}</li>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className="mt-1 ml-2 text-[#ffffff] group-hover:rotate-180 duration-300"
                  />
                </button>
                <div className="w-58">
                  {sideHizmetMenu &&
                    hizmetList.map((item) => (
                      <motion.ul
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: -10, opacity: 1 }}
                        transition={{ duration: 0.4 }}
                        className=" grid justify-start text-[#ffffff] hover:bg-orange duration-100 rounded-md"
                      >
                        <NavLink
                          className=" w-40 h-10 pt-2 pl-1 text-[#ffffff] duration-100 text-xl] rounded-md"
                          key={item._id}
                          to={item.link}
                          onClick={closeSideHizmetMenu}
                        >
                          {item.title}
                        </NavLink>
                      </motion.ul>
                    ))}
                </div>

                <NavLink
                  key={navBarList[2]._id}
                  className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
                  to={navBarList[2].link}
                  onClick={closeSideMenu}
                >
                  <li className=" mb-4 text-2xl ">{navBarList[2].title}</li>
                </NavLink>
                <NavLink
                  key={navBarList[3]._id}
                  className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
                  to={navBarList[3].link}
                  onClick={closeSideMenu}
                >
                  <li className=" mb-4 text-2xl ">{navBarList[3].title}</li>
                </NavLink>
                <NavLink
                  key={navBarList[4]._id}
                  className=" font-sans font-medium text-xl text-bold text-[#ffffff] decoration-[1px] hover:text-[#c6c6c6] shrink-0"
                  to={navBarList[4].link}
                  onClick={closeSideMenu}
                >
                  <li className=" mb-4 text-2xl ">{navBarList[4].title}</li>
                </NavLink>
              </div>
              <button className="flex align-top w-12 h-10 ">
                <FontAwesomeIcon
                  icon={faXmark}
                  className=" text-3xl text-[#ffffff] mt-2"
                  onClick={() => closeSideMenu()}
                />
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  )
}

export default Header
